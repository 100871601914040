import React from 'react'
import styled from 'styled-components'

import Map from 'components/map'

import media from 'styles/media'

const StyledWrapper = styled.section`
  margin-top: 80px;
`

const StyledTitle = styled.h2`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  line-height: 1.5;

  font-size: 36px;

  ${media.lg.min} {
    padding: 0 50px;
  }
`

const StyledDescription = styled.p`
  max-width: 1400px;
  margin: 10px auto 0 auto;
  padding: 0 20px;
  line-height: 2.5;

  ${media.lg.min} {
    padding: 0 50px;
  }
`
type SalesOfficesProps = {
  title: string
  description: string
  contactPins: Array<{}>
}

const SalesOffices: React.FC<SalesOfficesProps> = ({
  title,
  description,
  contactPins,
}) => {
  return (
    <StyledWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
      <Map contactPins={contactPins} />
    </StyledWrapper>
  )
}

export default SalesOffices
