import React from 'react'
import styled from 'styled-components'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import media from 'styles/media'

import arrowSimpleDown from 'assets/icons/arrow-simple-down.svg'

const StyledWrapper = styled.section`
  padding-top: 20px;
`

const StyledText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.green};
`

const StyledTitle = styled.p`
  margin-top: 20px;
  font-size: 36px;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
`

const StyledDescription = styled.p`
  margin-top: 10px;
  text-align: center;
  line-height: 2.5;
`

const StyledAccordionWrapper = styled.div`
  margin: 65px auto 0 auto;
  max-width: 900px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
`

const StyledAccordionItem = styled(AccordionItem)`
  margin-bottom: 43px;

  ${media.lg.min} {
    margin-bottom: 24px;
  }
`

const StyledAccordionItemButton = styled(AccordionItemButton)`
  padding: 35px 35px 20px 35px;
  font-weight: 500;
  border-radius: 34px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.orange};
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 62px;
    height: 62px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.orange};
    background-image: url('${arrowSimpleDown}');
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s;
  }

  &[aria-expanded='true']::before,
  &[aria-selected='true']::before {
    transform: translate(-50%, -50%) rotate(180deg);
    background-color: ${({ theme }) => theme.colors.green};
  }

  ${media.lg.min} {
    padding: 0;
    display: flex;
    align-items: center;
    padding-left: 60px;
    height: 62px;

    &:before {
      top: -1px;
      bottom: 0;
      right: -2px;
      left: auto;
      transform: translate(0, 0);
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.orange};
      background-image: url('${arrowSimpleDown}');
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.3s;
    }

    &[aria-expanded='true']::before,
    &[aria-selected='true']::before {
      transform: translate(0, 0) rotate(180deg);
      background-color: ${({ theme }) => theme.colors.green};
    }
  }
`
const StyledAccordionItemHeading = styled(AccordionItemHeading)``

const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  background-color: ${({ theme }) => theme.colors.lightgreen};
  border-radius: 0 0 34px 34px;
  padding: 53px 29px 50px 29px;
  line-height: 2.5;
  z-index: -100;
  position: relative;
  top: -30px;
  transform-origin: top;

  animation: expand 0.3s ease-out;

  @keyframes expand {
    0% {
      transform: scaleY(0);
    }

    100% {
      transform: scaleY(1);
    }
  }
`

type FaqProps = {
  title: string
  subtitle: string
  questionsAndAnswers: Array<{
    contactQuestionsSingle: string
    contactQuestionsDescriptionSingle: string
  }>
}

const Faq: React.FC<FaqProps> = ({ title, subtitle, questionsAndAnswers }) => {
  return (
    <StyledWrapper>
      <StyledText>Kontakt</StyledText>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{subtitle}</StyledDescription>
      <StyledAccordionWrapper>
        <Accordion allowZeroExpanded={true}>
          {questionsAndAnswers.map((el, index) => {
            return (
              <StyledAccordionItem key={index}>
                <StyledAccordionItemHeading>
                  <StyledAccordionItemButton>
                    {el.contactQuestionsSingle}
                  </StyledAccordionItemButton>
                </StyledAccordionItemHeading>
                <StyledAccordionItemPanel>
                  <p>{el.contactQuestionsDescriptionSingle}</p>
                </StyledAccordionItemPanel>
              </StyledAccordionItem>
            )
          })}
        </Accordion>
      </StyledAccordionWrapper>
    </StyledWrapper>
  )
}

export default Faq
