import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/layout'

import Header from 'components/sections/main-header'

import Faq from 'components/sections/faq'

import { KontaktPageQuery } from 'types/graphql'
import SalesOffices from 'components/sections/sales-offices'
import Newsletter from 'components/sections/newsletter'
import Seo from 'components/layout/seo'

const Kontakt: React.FC<PageProps<KontaktPageQuery>> = ({ data }) => {
  const PAGE = data?.wpPage?.kontakt

  const HERO_IMAGE = {
    src: PAGE?.contactHeroImg?.localFile?.childImageSharp?.gatsbyImageData!,
    alt: PAGE?.contactHeroImg?.altText!,
  }

  return (
    <Layout>
      <Seo
        title={data?.wpPage?.seo?.title!}
        description={data?.wpPage?.seo?.metaDesc!}
        ogTitle={data?.wpPage?.seo?.opengraphTitle!}
        ogDescription={data?.wpPage?.seo?.opengraphDescription!}
        ogImage={data?.wpPage?.seo?.opengraphImage?.sourceUrl!}
      />
      <Header title={PAGE?.contactHeroTitle!} image={HERO_IMAGE} />
      <Faq
        title={PAGE?.contactQuestionsTitle!}
        subtitle={PAGE?.contactQuestionsSubtitle!}
        questionsAndAnswers={PAGE?.contactFaq!}
      />

      <SalesOffices
        title={PAGE?.contactOfficeTitle!}
        description={PAGE?.contactOfficeDescription}
        contactPins={PAGE?.contactPins!}
      />

      <Newsletter
        backgroundColor="lightorange"
        noMarginTop
        withImage
        womanImage
      />
    </Layout>
  )
}

export default Kontakt

export const query = graphql`
  query KontaktPage {
    wpPage(slug: { regex: "/kontakt/" }) {
      id
      kontakt {
        contactHeroTitle
        contactQuestionsTitle
        contactQuestionsSubtitle
        contactPins {
          contactPinsInvestment
          contactPinsMapLat
          contactPinsMapLng
        }
        contactOfficeTitle
        contactOfficeDescription
        contactHeroImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        contactFaq {
          contactQuestionsDescriptionSingle
          contactQuestionsSingle
        }
      }
      seo {
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`
